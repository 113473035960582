.notification .Toastify__progress-bar {
    visibility: hidden;
}

div[data-tour-elem="controls"] {
    justify-content: space-between !important;
    margin-top: 10px;
}

.MuiTypography-caption {
    font-family: Montserrat, serif !important;
}

.notification {
    padding: 0;
    background: none;
    box-shadow: none;
}

html {
    overflow: hidden;
}

.k-animation-container {
    zIndex: 9999;
}


*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2);
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
    position: absolute;
}

*::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 5px;
}